// Import necessary libraries and components
import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Toolbar,
    Typography,
    Divider,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import Drawer from '@mui/material/Drawer';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import logo from '../img/logo.png';
import axios from 'axios';

// Custom theme for MUI
const theme = createTheme({
    palette: {
        primary: {
            main: '#3B4876',
        },
    },
});

// Drawer width for layout
const drawerWidth = 280;

// Main function component
export default function Annonce() {
    // States for managing data and dialog state
    const [Annonces, setAnnonces] = useState([]);
    const [open, setOpen] = useState(false);
    const [isAdding, setIsAdding] = useState(true);
    const [currentAnnonce, setCurrentAnnonce] = useState({ id: null, name: '' });
    const [pdfFile, setPdfFile] = useState(null); // State to manage PDF file
    const [imageFile, setImageFile] = useState(null); // State to manage image file
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [TypeAnnonces, setTypeAnnonces] = useState([]);
    const [TypeAnnonce, setTypeAnnonce] = useState("");

    const navigate = useNavigate();

    const getTypeAnn = () => {
        axios.get("https://serv.taghzawt.yuliagroupe.com/api/typeannonces")
            .then((res) => {
                setTypeAnnonces(res.data);
            });
    }

    const getAnn = () => {
        axios.get("https://serv.taghzawt.yuliagroupe.com/api/annonces")
            .then((res) => {
                setAnnonces(res.data);
                console.log(res.data);
            });
    }

    useEffect(() => {
        getTypeAnn();
        getAnn();
    }, []);

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Get the current data to display on the current page
    const currentData = Annonces.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    // Open the dialog for adding a new Annonce
    const handleOpenAdd = () => {
        setIsAdding(true);
        setCurrentAnnonce({ id: null, name: '' });
        setPdfFile(null); // Reset the PDF file state
        setImageFile(null); // Reset the image file state
        setOpen(true);
    };

    // Open the dialog for editing an existing Annonce
    const handleOpenEdit = (Annonce) => {
        setIsAdding(false);
        setCurrentAnnonce({ id: Annonce.ID_Annonce, name: Annonce.Label });
        setPdfFile(null); // Reset the PDF file state
        setImageFile(null); // Reset the image file state
        setOpen(true);
    };

    // Close the dialog
    const handleClose = () => {
        setOpen(false);
    };

    // Handle form submission
    const handleSubmit = () => {
        const formData = new FormData();
        formData.append('Label', currentAnnonce.name);
        formData.append('file', pdfFile);
        formData.append('image', imageFile);
        formData.append('ID_Typeannonce', TypeAnnonce); // Add TypeAnnonce to formData

        if (isAdding) {
            // Add a new Annonce with the provided data and files (if any)
            axios.post("https://serv.taghzawt.yuliagroupe.com/api/annonces", formData)
                .then((res) => {
                    getAnn();
                    console.log(res.data);
                })
                .catch((error) => {
                    console.error("Error adding Annonce:", error);
                });
        } else {
            // Update the existing Annonce with the provided data and files (if any)
            axios.post("https://serv.taghzawt.yuliagroupe.com/api/annonces/" + currentAnnonce.id, formData)
                .then((res) => {
                    getAnn();
                })
                .catch((error) => {
                    console.error("Error updating Annonce:", error);
                });
        }
        handleClose();
    };

    // Handle file change event
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setPdfFile(file); // Update the state with the selected file
    };

    // Handle image file change event
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImageFile(file); // Update the state with the selected image file
    };

    const HandleDelete = (T) => {
        axios.delete("https://serv.taghzawt.yuliagroupe.com/api/annonces/" + T.ID_Annonce)
            .then((res) => {
                getAnn();
            });
    }

    // Render the component
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '600px' }}>
                {/* Permanent drawer for navigation */}
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            background: '#3B4876',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    <Box>
                        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={logo} alt="logo" width="160" />
                        </Toolbar>
                        <Box sx={{ overflow: 'auto', marginTop: '12px' }}>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => navigate("/Admin/Projet")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Projets
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/Annonce")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Annonces
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/TypeAnnonce")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Type Annonces
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/TypeBuilding")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Type Logement
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/ResponceAdmin")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Q&A
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/ListAttende")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            liste d'attente
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                            sx={{
                                width: '100%',
                                borderRadius: 0,
                                mt: 3,
                                mb: 2,
                                backgroundColor: '#fff',
                                '&:hover': { backgroundColor: 'white', color: 'black' },
                            }}
                        >
                            Deconnecter
                        </Button>
                    </Box>
                </Drawer>
                {/* Main content */}
                <Container sx={{ marginTop: 5 }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Gestion des Annonces
                    </Typography>

                    {/* Add Annonce button */}
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        style={{ marginBottom: '20px' }}
                        onClick={handleOpenAdd}
                    >
                        Ajouter Annonce
                    </Button>

                    {/* Table for displaying Annonces */}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Annonces</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentData.map((Annonce) => (
                                    <TableRow key={Annonce.id}>
                                        <TableCell>{Annonce.ID_Annonce}</TableCell>
                                        <TableCell>{Annonce.Label}</TableCell>
                                        <TableCell>
                                            {/* Edit button */}
                                            <IconButton onClick={() => handleOpenEdit(Annonce)}>
                                                <Edit color="primary" />
                                            </IconButton>
                                            {/* Delete button (you should implement delete functionality) */}
                                            <IconButton onClick={() => HandleDelete(Annonce)}>
                                                <Delete color="error" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={Annonces.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Lignes par page"
                        />
                    </TableContainer>

                    {/* Dialog for adding or editing an Annonce */}
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>{isAdding ? 'Ajouter Annonce' : 'Modifier Annonce'}</DialogTitle>
                        <DialogContent sx={{ marginTop: 2, display: "flex", flexDirection: "column", rowGap: "20px" }}>
                            {/* Input field for Annonce name */}
                            <TextField
                                sx={{ marginTop: 1 }}
                                label="Nom d'annonce"
                                value={currentAnnonce.name}
                                onChange={(e) => setCurrentAnnonce({ ...currentAnnonce, name: e.target.value })}
                                fullWidth
                            />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Type Annonce</InputLabel>
                                <Select label="Type Annonce" fullWidth value={TypeAnnonce} onChange={(e) => setTypeAnnonce(e.target.value)}>
                                    {TypeAnnonces.map((TypeAnnonce) => (
                                        <MenuItem value={TypeAnnonce.ID_Typeannonce}>{TypeAnnonce.type}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* Input field for uploading PDF file */}
                            <TextField
                                label="Upload PDF"
                                type="file"
                                inputProps={{ accept: '.pdf', name: 'file' }} // Accept only PDF files
                                onChange={handleFileChange}
                                fullWidth
                                InputLabelProps={{ shrink: true }} // Ensures the label remains visible
                            />
                            {/* Display the name of the selected file, if any */}
                            {pdfFile && (
                                <Typography variant="body2">
                                    fichier selectioné: {pdfFile.name}
                                </Typography>
                            )}
                            {/* Input field for uploading image file */}
                            <TextField
                                label="Upload Image"
                                type="file"
                                inputProps={{ accept: 'image/*', name: 'image' }} // Accept only image files
                                onChange={handleImageChange}
                                fullWidth
                                InputLabelProps={{ shrink: true }} // Ensures the label remains visible
                            />
                            {/* Display the name of the selected image file, if any */}
                            {imageFile && (
                                <Typography variant="body2">
                                    image selectionée: {imageFile.name}
                                </Typography>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Annuler
                            </Button>
                            <Button onClick={handleSubmit} color="primary">
                                {isAdding ? 'Ajouter' : 'Sauvegarder'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Container>
            </Box>
        </ThemeProvider>
    );
}
