import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import "@fontsource/inter/600.css";
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import logo from '../img/logo.png';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    Typography,
    Container,
    TablePagination,
  } from '@mui/material';
import { Delete, Edit, Add } from '@mui/icons-material';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';

const drawerWidth = 280;
const theme = createTheme({
  palette: {
      primary: {
          main: '#3B4876',
      },
  },
});

export default function Projet() {
  const [projects, setProjects] = useState([]);
  const getProjet=()=>{
    axios.get("https://serv.taghzawt.yuliagroupe.com/api/projects")
    .then((res)=>{
      setProjects(res.data);
    })
  }
  useEffect(()=>{
    getProjet()
  },[])

  const [open, setOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(true);
  const [currentProject, setCurrentProject] = useState({ ID_Project:null ,Project: '' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const navigate = useNavigate()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const currentData = projects.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleOpenAdd = () => {
    setIsAdding(true);
    setCurrentProject({ ID_Project:null ,Project: ''  });
    setOpen(true);
  };

  const handleOpenEdit = (project) => {
    setIsAdding(false);
    setCurrentProject(project);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (isAdding) {
      const newProject = { Project: currentProject.Project };
      axios.post("https://serv.taghzawt.yuliagroupe.com/api/projects",newProject)
      .then((res)=>{
        setProjects([...projects, res.data]);
        setCurrentProject({ID_Project:null ,Project: '' })
      })
      
      
    } else {

      const updateProject = { Project: currentProject.Project };

      axios.put("https://serv.taghzawt.yuliagroupe.com/api/projects/"+currentProject.ID_Project,updateProject)
      .then((res)=>{
        getProjet()
        setCurrentProject({ID_Project:null ,Project: '' })
      })

    }
    handleClose();
  };
  const HandleDelete=(p)=>{
    
    axios.delete("https://serv.taghzawt.yuliagroupe.com/api/projects/"+p.ID_Project)
    .then((res)=>{
      getProjet()
    })

  }
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '600px' }}>
      <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            background: '#3B4876',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    <Box>
                        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={logo} alt="logo" width="160" />
                        </Toolbar>
                        <Box sx={{ overflow: 'auto', marginTop: '12px' }}>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => navigate("/Admin/Projet")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Projets
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/Annonce")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Annonces
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/TypeAnnonce")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Type Annonces
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/TypeBuilding")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Type Logement
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/ResponceAdmin")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Q&A
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/ListAttende")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            liste d'attente
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                            sx={{
                                width: '100%',
                                borderRadius: 0,
                                mt: 3,
                                mb: 2,
                                backgroundColor: '#fff',
                                '&:hover': { backgroundColor: 'white', color: 'black' },
                            }}
                        >
                            Deconnecter
                        </Button>
                    </Box>
                </Drawer>
        <Container sx={{ marginTop: 5 }}>
          <Typography variant="h4" align="center" gutterBottom>
            Gestion de Projets
          </Typography>

          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            style={{ marginBottom: '20px' }}
            onClick={handleOpenAdd}
          >
            Ajouter Projet
          </Button>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Projet</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentData.map((project) => (
                  <TableRow key={project.ID_Project}>
                    <TableCell>{project.ID_Project}</TableCell>
                    <TableCell>{project.Project}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpenEdit(project)}>
                        <Edit color="primary" />
                      </IconButton>
                      <IconButton>
                        <Delete color="error" onClick={() => HandleDelete(project)}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={projects.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 20, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Lignes par page"
            />
          </TableContainer>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{isAdding ? 'Ajouter Project' : 'Modifier Projet'}</DialogTitle>
            <DialogContent >
              <TextField
              sx={{marginTop:1}}
                label="Nom de projet"
                value={currentProject.Project}
                onChange={(e) => setCurrentProject({ ...currentProject, Project: e.target.value })}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Annuler
              </Button>
              <Button onClick={handleSubmit} color="primary">
                {isAdding ? 'Ajouter' : 'Sauvegarder'}
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
