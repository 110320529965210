import { Box } from "@mui/material"
import FormComponent from "../components/FormComponent"
import Nav from "../components/Nav"
import Footer from "../components/Footer"

const InscriptionWydadia=()=>{
return(
    <>
    <Nav />
    <Box sx={{width:"100%" , display:"flex" , justifyContent:"center" , marginTop:13,mb:2}}>
    <FormComponent/>
    </Box>
    <Footer/>
    </>
)
}

export default InscriptionWydadia