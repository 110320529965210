import axios from "axios"
import { useEffect, useState } from "react"
import Nav from "../components/Nav"
import { Box, Card, CardContent, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import Footer from "../components/Footer"

export default function QAProjectChoice(){
    const [QAP , setQAP] =useState([])
    const navigate = useNavigate()
    useEffect(()=>{
        axios.get("https://serv.taghzawt.yuliagroupe.com/api/projects")
        .then((res)=>{
            setQAP(res.data)
        })
    },[])
    return(
        <>
        <Nav/>
        <Box component="main" sx={{ flexGrow: 1, p: 0 ,marginTop:4 ,mb:2,  display:"flex", flexDirection:"column" ,rowGap:2 , alignItems:"center" , mt:12}}>
        <Typography variant='h4'  sx={{color:"#3B4876",fontWeight:700}}>Q&A أسئلة و أجوبة</Typography>
        {QAP.map((QA)=>(
            <Card sx={{ minWidth: 200, background: "#F1F3F4", boxShadow: "none", width: { lg: "82%", md: "80%", sm: "90%", xs: "100%" } }} onClick={()=>navigate("/QA/"+QA.ID_Project)}>
            <CardContent sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography sx={{ fontSize: { lg: 20, md: 18, sm: 15, xs: 13 }, color: "#3B4876", fontFamily: "inherit", fontWeight: "700", textAlign: { xs: "center" }, width: "90%" }} color="text.secondary" gutterBottom>
              FAQ de {QA.Project}
              </Typography>
            </CardContent>
          </Card>
                ))}
    </Box>
    <Footer/>
    </>
    )
}