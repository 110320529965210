import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Accueil from "./pages/Acceuill";
import Annonces from "./pages/Annonces";
import Annonce from "./pages/Annonce";
import TypeAnnonce from "./pages/TypeAnnonce";
import Login from "./pages/Login";
import Projet from './pages/Projet';
import TypeBuilding from './pages/TypeBuilding';
import StyledTable from './components/StyledTable';
import QAComponent from './components/QAComponent';
import ResponceAdmin from './components/ResponceAdmin';
import InscriptionWydadia from './pages/InscriptionWydadia';
import ContacterNous from './pages/ContacterNous';
import QAProjectChoice from './pages/QAProjectChoice';
import ListAttende from './pages/ListAttende';
export default function App() {
    return (
        <Router>
            <Routes>
                <Route path="/AdminControle/login" element={<Login />} />
                <Route path="/Demmande" element={<StyledTable />} />
                <Route path="/Admin/ListAttende" element={<ListAttende />} />
                <Route path="/QA/:id" element={<QAComponent />} />
                <Route path="/QAP" element={<QAProjectChoice />} />
                <Route path="/" element={<Accueil />} />
                <Route path='/inscriptionWydadia' element={<InscriptionWydadia/>} />
                <Route path='/ContacterNous' element={<ContacterNous/>} />
                <Route path="/Annonces/:project" element={<Annonces />} />
                <Route path='/Admin/Projet' element={<Projet/>} />
                <Route path='/Admin/Annonce' element={<Annonce/>} />
                <Route path='/Admin/TypeAnnonce' element={<TypeAnnonce/>} />
                <Route path='/Admin/TypeBuilding' element={<TypeBuilding/>} />
                <Route path='/Admin/ResponceAdmin' element={<ResponceAdmin/>} />
            </Routes>
        </Router>
    );
}
