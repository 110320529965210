import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
import logo from '../img/logo.png'; // Ensure this import is correct

const drawerWidth = "100vw";

function Nav(props) {
    const navigate = useNavigate();
    const [projects, setProjects] = React.useState([]);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const getProjet = () => {
        axios.get("https://serv.taghzawt.yuliagroupe.com/api/projects")
            .then((res) => {
                setProjects(res.data);
            });
    };

    useEffect(() => {
        getProjet();
    }, []);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handlechangesoustype = (type) => {
        props.setselectTypeAnnonce(type.ID_Typeannonce);
        handleDrawerToggle();
    };

    const drawer = (
        <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                MUI
            </Typography>
            <Divider />
                <List disablePadding>
                        <ListItem disablePadding onClick={() => navigate("/")}>
                            <ListItemButton>
                                <Typography sx={{ mr:3,textAlign: "center", fontFamily: "Inter", fontWeight: "700", color: "#3B4876", width: "100%" }}>
                                Acceuill
                                </Typography>
                            </ListItemButton>
                        </ListItem>
                </List>
                <Divider sx={{ borderBottomWidth: "medium", background: "#3B4876" }} />
            {projects.map((project) => (
                <Accordion
                    key={project.ID_Project}
                    expanded={expanded === project.ID_Project}
                    onChange={handleChange(project.ID_Project)}
                    onClick={()=>navigate("/Annonces/"+project.ID_Project)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${project.ID_Project}-content`}
                        id={`${project.ID_Project}-header`}
                        sx={{'& .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded,.css-eqpfi5-MuiAccordionSummary-content,.css-l0jafl':{display:"flex",justifyContent:"center", fontFamily: "Inter", fontWeight: "700", color: "#3B4876", width: "100%"} }}
                    >
                        {project.Project}
                    </AccordionSummary>
                    <AccordionDetails>
                        {project.type_annonces ? project.type_annonces.map((type) => (
                            <React.Fragment key={type.ID_Typeannonce}>
                                <Divider sx={{ borderBottomWidth: "medium", background: "#3B4876" }} />
                                <List disablePadding>
                                    <ListItem disablePadding onClick={() => handlechangesoustype(type)}>
                                        <ListItemButton>
                                            <Typography sx={{ textAlign: "center", fontFamily: "Inter", fontWeight: "700", color: "#3B4876", width: "100%" }}>
                                                {type.type}
                                            </Typography>
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </React.Fragment>
                        )) : null}
                        <Divider sx={{ borderBottomWidth: "medium", background: "#3B4876" }} />
                    </AccordionDetails>
                </Accordion>
            ))}
            <Divider sx={{ borderBottomWidth: "medium", background: "#3B4876" }} />
                <List disablePadding>
                        <ListItem disablePadding onClick={() => navigate("/inscriptionWydadia")}>
                            <ListItemButton>
                                <Typography sx={{ mr:3,textAlign: "center", fontFamily: "Inter", fontWeight: "700", color: "#3B4876", width: "100%" }}>
                                التسجيل في الودادية
                                </Typography>
                            </ListItemButton>
                        </ListItem>
                </List>
                <Divider sx={{ borderBottomWidth: "medium", background: "#3B4876" }} />
                <List disablePadding>
                        <ListItem disablePadding onClick={() => navigate("/QAP")}>
                            <ListItemButton>
                                <Typography sx={{ mr:3,textAlign: "center", fontFamily: "Inter", fontWeight: "700", color: "#3B4876", width: "100%" }}>
                                FAQ<br></br>أسئلة و اجوبة
                                </Typography>
                            </ListItemButton>
                        </ListItem>
                </List>
                <Divider sx={{ borderBottomWidth: "medium", background: "#3B4876" }} />
                <List disablePadding>
                        <ListItem disablePadding onClick={() => navigate("/ContacterNous")}>
                            <ListItemButton>
                                <Typography sx={{ mr:3,textAlign: "center", fontFamily: "Inter", fontWeight: "700", color: "#3B4876", width: "100%" }}>
                                Contactez-nous
                                </Typography>
                            </ListItemButton>
                        </ListItem>
                </List>
                <Divider sx={{ borderBottomWidth: "medium", background: "#3B4876" }} />
        </Box>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar component="nav" sx={{ background: '#3B4876', boxShadow: 'none', zIndex: 12005 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ display: { sm: 'block' } }}
                        onClick={()=>navigate("/")}
                    >
                        <img src={logo} alt='logo' width='160' />
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'flex', columnGap: "20px" }, width: "90%", marginLeft: "55px" }}>
                        <Button sx={{ color: '#fff', fontWeight: '650', fontSize: '14px' }} onClick={() => navigate("/")}>Accueil</Button>
                        {projects.map((project) => (
                            <Button key={project.ID_Project} sx={{ color: '#fff', fontWeight: '650', fontSize: '14px' }} onClick={() => navigate("/Annonces/" + project.ID_Project)}>{project.Project}</Button>
                        ))}
                        <Button sx={{ color: '#fff', fontWeight: '650', fontSize: '14px' }} onClick={() => navigate("/inscriptionWydadia")}>التسجيل في الودادية</Button>
                        <Button sx={{ color: '#fff', fontWeight: '650', fontSize: '14px' }} onClick={() => navigate("/QAP")}>FAQ<br></br>أسئلة و أجوبة</Button>
                        <Button sx={{ color: '#fff', fontWeight: '650', fontSize: '14px' }} onClick={() => navigate("/ContacterNous")}>Contactez-nous</Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav" sx={{ zIndex: 1251 }}>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        background: "#10002b",
                        zIndex: 1251
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

export default Nav;
