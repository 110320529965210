import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import Nav from '../components/Nav';
import Footer from '../components/Footer';

const ContacterNous = () => {
  const [formData, setFormData] = useState({
    nomPrenom: '',
    email: '',
    message: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData); // Replace with your submit logic
  };
  const reset = () => {
    setFormData({
      nomPrenom: '',
      email: '',
      message: ''
    }
    )
  }
  return (
    <>
    <Box sx={{display:"flex" , justifyContent:"center" , width:'100%' , mt:7,mb:2}}>
    <Nav/>
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="fit-content"
      sx={{ width: { xl: "50%", md: "50%", sm: "50%", xs: "70%" }, mt: 4 }}
    >
          <Typography sx={{color:"#3B4876",fontWeight:700}} variant="h4" align="center" gutterBottom>
            Contactez Nous
          </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ backgroundColor: '#FFF', borderRadius: 1, boxShadow: 1, p: 3, width: '100%' }}
      >
        <TextField
          fullWidth
          label="Nom Et Prenom"
          name="nomPrenom"
          value={formData.nomPrenom}
          onChange={handleChange}
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          variant="outlined"
          multiline
          rows={4}
          sx={{ mb: 2 }}
        />
        <Box display="flex" sx={{display:"flex", justifyContent:"space-around"}} >
          <Button type="submit" variant="contained" sx={{ width:"45%" ,background: '#3B4876', "&:hover": { background: "#fff", color: "#3B4876" } }}>
            Envoyer
          </Button>
          <Button variant="contained" onClick={reset}  sx={{ width:"45%" ,background: '#3B4876',"&:hover": { background: "#fff", color: "#3B4876" } }}>
            Annuler
          </Button>
        </Box>
      </Box>
    </Box>
    </Box>
    <Footer/>
    </>
  );
};

export default ContacterNous;
