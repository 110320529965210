import { Box, Button, Divider } from "@mui/material";
import SwiperC from "../components/Swiper";
import pdf from "../img/fichier-pdf.png"
import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import PinDropIcon from '@mui/icons-material/PinDrop';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import axios from "axios";
import FormComponent from "../components/FormComponent";
import Footer from "../components/Footer";
export default function Accueil(){
    const [iframeURL , setiframURL] = useState('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.8292118718323!2d-6.920915188600181!3d33.89405212595472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda712751dc3fc81%3A0x6f7416a7f44cfbb8!2sTaghzawt%201!5e0!3m2!1sen!2sma!4v1714680452240!5m2!1sen!2sma')
    const [ Selected , setSeleted ] = useState(1)
    const [Annonces , setAnnonces] = useState([])
    const ChangeUrlMap= (Selected , Url) =>{
      setiframURL(Url)
      setSeleted(Selected)
    }

    const getAnn=()=>{
      axios.get("https://serv.taghzawt.yuliagroupe.com/api/annonces")
      .then((res)=>{
        setAnnonces(res.data);
        console.log(res.data)
      })
    }
    
          useEffect(()=>{
            getAnn()
          },[])
          function formatDate(isoDateString) {
            // Define month names in French
            const monthNames = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
        
            // Create a Date object from the ISO 8601 date string
            const date = new Date(isoDateString);
        
            // Extract the day, month, and year
            const day = date.getDate();
            const month = monthNames[date.getMonth()];
            const year = date.getFullYear();
        
            // Format the date as "15 mai 2024"
            return `${day} ${month} ${year}`;
        }
return(
    <>
    <SwiperC/>
    <Box component="main" sx={{ flexGrow: 1, p: 0 ,marginTop:4 ,  display:"flex", flexDirection:"column" ,rowGap:2 , alignItems:"center" }}>
    {Annonces.map((ann)=>(
    <Card sx={{ minWidth: 200, background: "#F1F3F4", boxShadow: "none",cursor: 'pointer', width: { lg: "82%", md: "80%", sm: "90%", xs: "100%" } }} onClick={()=>window.open("https://serv.taghzawt.yuliagroupe.com/api/annonces/"+ann.ID_Annonce+"/file")}>
    <CardContent sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Typography sx={{ fontSize: { lg: 20, md: 18, sm: 15, xs: 13 }, color: "#3B4876", fontFamily: "inherit", fontWeight: "700", textAlign: { xs: "center" }, width: "90%" }} color="text.secondary" gutterBottom>
        {ann.Label}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", width: "10%" , rowGap:1,flexDirection:"column" , alignItems:"center"}}>
        <img src={pdf} height={40} alt="pdf" />
        <Typography sx={{color: "#3B4876"}}>{formatDate(ann.created_at)}</Typography>
      </Box>
    </CardContent>
  </Card>
        ))}

      <FormComponent/>
        <Footer/>
      </Box>
    </>
)
}