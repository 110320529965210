import React, {  useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import "@fontsource/inter/600.css";
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import logo from '../img/logo.png';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    Typography,
    Container,
    TablePagination,
  } from '@mui/material';
  import { Delete, Edit, Add } from '@mui/icons-material';
  import { useState } from 'react';
  import Dialog from '@mui/material/Dialog';
  import DialogTitle from '@mui/material/DialogTitle';
  import DialogContent from '@mui/material/DialogContent';
  import DialogActions from '@mui/material/DialogActions';
  import TextField from '@mui/material/TextField';
  import { useNavigate } from 'react-router-dom';
const drawerWidth = 280;
const theme = createTheme({
  palette: {
      primary: {
          main: '#3B4876',
      },
  },
});
const ListAttende = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://serv.taghzawt.yuliagroupe.com/api/demmandes');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
    <ThemeProvider theme={theme}>
    <Box sx={{ display: 'flex', minHeight: '600px' }}>
    <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            background: '#3B4876',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    <Box>
                        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={logo} alt="logo" width="160" />
                        </Toolbar>
                        <Box sx={{ overflow: 'auto', marginTop: '12px' }}>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => navigate("/Admin/Projet")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Projets
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/Annonce")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Annonces
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/TypeAnnonce")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Type Annonces
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/TypeBuilding")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Type Logement
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/ResponceAdmin")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Q&A
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/ListAttende")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            liste d'attente
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                            sx={{
                                width: '100%',
                                borderRadius: 0,
                                mt: 3,
                                mb: 2,
                                backgroundColor: '#fff',
                                '&:hover': { backgroundColor: 'white', color: 'black' },
                            }}
                        >
                            Deconnecter
                        </Button>
                    </Box>
                </Drawer>
        <Box sx={{display:"flex" ,mt:8, flexDirection:"column" , alignItems:"center" , width:"100%"}}>
        <Typography variant="h4" align="center" gutterBottom>
            Liste d'attende
          </Typography>
        
    <TableContainer component={Paper} >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><b>Nom et Prenom</b></TableCell>
            <TableCell align="left"><b>Type</b></TableCell>
            <TableCell align="left"><b>Date</b></TableCell>
            <TableCell align="left"><b>telephone</b></TableCell>
            <TableCell align="left"><b>telephone 2</b></TableCell>
            <TableCell align="left"><b>email</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:nth-of-type(even)': { backgroundColor: '#f5f5f5' }, '&:hover': { backgroundColor: '#97aeff70' } }}
            >
              <TableCell component="th" scope="row">
                {`${row.nom} ${row.prenom}`}
              </TableCell>
              <TableCell align="left">{row.type}</TableCell>
              <TableCell align="left">{row.created_at.split('T')[0]}</TableCell>
              <TableCell align="left">{row.telephone}</TableCell>
              <TableCell align="left">{row.telephone2}</TableCell>
              <TableCell align="left">{row.email}</TableCell> {/* Adjust based on date format */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
   </Box> 
    </ThemeProvider>
    </>
  );
};

export default ListAttende;
