import React, { useState } from 'react';
import axios from 'axios';
import { Box, TextField, Button, MenuItem, Select, InputLabel, FormControl } from '@mui/material';

const QAFormComponent = (props) => {
  const [formData, setFormData] = useState({
    nom: '',
    msg: '',
    Projets:props.idProject,
    email:'',
    telephone:''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://serv.taghzawt.yuliagroupe.com/api/tickets', formData);
      console.log('Ticket created:', response.data);
      // Optionally, you can reset the form after successful submission
      setFormData({
        nom: '',
        msg: '',
        Projets:props.idProject,
        email:'',
        telephone:''
      });
      props.getticket()
    } catch (error) {
      console.error('Error creating ticket:', error);
    }
  };
  const reset = ()=>{
    setFormData({
      nom: '',
      msg: '',
      Projets:props.idProject,
      email:'',
      telephone:''
    });
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="fit-content"
      sx={{ width: { xl: "66%", md: "66%", sm: "66%", xs: "90%" }, mt: 4 }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ backgroundColor: '#F1F3F4', borderRadius: 1, boxShadow: 1, p: 3, width: '100%' }}
      >
        <TextField
          fullWidth
          label="Nom et Prenom"
          name="nom"
          value={formData.nom}
          onChange={handleChange}
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Telephone"
          name="telephone"
          value={formData.telephone}
          onChange={handleChange}
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Message"
          name="msg"
          value={formData.msg}
          onChange={handleChange}
          variant="outlined"
          multiline
          rows={4}
          sx={{ mb: 2 }}
        />
        <Box display="flex" justifyContent="space-between">
          <Button type="submit" variant="contained" sx={{ background: '#3B4876', "&:hover": { background: "#fff", color: "#3B4876" } }}>
            Envoyer
          </Button>
          <Button variant="contained" onClick={reset} sx={{ background: '#3B4876', "&:hover": { background: "#fff", color: "#3B4876" } }}>
            Annuler
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default QAFormComponent;
