import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import logo from '../img/logo.png'
import axios from 'axios';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://yuliagroup.com/">
                yuliagroup
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const textFieldStyle = {
    borderRadius: '30px',
};

const theme = createTheme({
    palette: {
        primary: {
            main: '#fff' // Customize this color
        },
  
    },
  });
export default function Login() {
    const [login,setlogin]=React.useState()
    const [password , setpassword]=React.useState()
    const navigate = useNavigate()
    React.useEffect(()=>{
        const userData = JSON.parse(localStorage.getItem("userData"))
        if(userData){
            navigate("/Dashboard")
        }
    })
    const handleSubmit = (event) => {
        event.preventDefault();
        let data = {
            email : login,
            password : password
        }
        axios.post("https://serv.taghzawt.yuliagroupe.com/api/utilisateurs/login",data)
        .then((res)=>{
            if(!res.data.error){
                const userData = JSON.stringify(res.data.user)
                localStorage.setItem("userDataTaghzawt",userData)
                navigate("/Admin/Projet")
            }else{
                alert(res.data.error)
            }
        })
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" >
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '30px',
                        background:"#3B4876",
                        borderRadius: '50px',
                        textAlign:"center",
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={logo} alt='logo' style={{ width: '50%', height: '50%', borderRadius: '30%' }} />
                    </Box>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            value={login}
                            onChange={(e)=>setlogin(e.target.value)}
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            sx={{
                                // Styles for the input field
                                '& .MuiInputBase-input': {
                                  color: 'white', // White text
                                },
                                '& .MuiInputBase-root': {
                                  borderColor: 'white', // White border
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'white', // White border (for outlined variant)
                                },
                                // Styles for the placeholder and label
                                '& .MuiInputLabel-root': {
                                  color: 'white', // White label text
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                  color: 'white', // White label text when focused
                                },
                                '& .MuiInputBase-input::placeholder': {
                                  color: 'white', // White placeholder text
                                },
                                // Additional hover and focus styles
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'white', // White border on hover
                                },
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'white', // White border when focused
                                },
                              }}
                            
                        />
                        <TextField

                            margin="normal"
                            required
                            fullWidth
                            value={password}
                            onChange={(e)=>setpassword(e.target.value)}
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            style={textFieldStyle}
                            sx={{
                                // Styles for the input field
                                '& .MuiInputBase-input': {
                                  color: 'white', // White text
                                },
                                '& .MuiInputBase-root': {
                                  borderColor: 'white', // White border
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'white', // White border (for outlined variant)
                                },
                                // Styles for the placeholder and label
                                '& .MuiInputLabel-root': {
                                  color: 'white', // White label text
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                  color: 'white', // White label text when focused
                                },
                                '& .MuiInputBase-input::placeholder': {
                                  color: 'white', // White placeholder text
                                },
                                // Additional hover and focus styles
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'white', // White border on hover
                                },
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'white', // White border when focused
                                },
                              }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2, backgroundColor: '#fff','&:hover': { backgroundColor: 'white', color: '#3B4876' } }}
                            style={textFieldStyle}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 4, mb: 4 }} />
            </Container>
            </ThemeProvider>
    );
}