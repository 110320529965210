import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, TextField, Button, MenuItem, Select, InputLabel, FormControl, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const FormComponent = () => {
  const [formData, setFormData] = useState({
    nomEtPrenom: '',
    typeBuilding: '',
    telephone: '',
    telephone2: '',
    email: '',
  });
  const [types , settypes ] = useState([])
  const navigate = useNavigate()
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(()=>{
    axios.get("https://serv.taghzawt.yuliagroupe.com/api/typebuildings")
    .then((res)=>{
      settypes(res.data)
    })
  },[])
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://serv.taghzawt.yuliagroupe.com/api/demmandes', {
        nom: formData.nomEtPrenom.split(' ')[0],
        prenom: formData.nomEtPrenom.split(' ')[1],
        typeBuilding: formData.typeBuilding,
        telephone: formData.telephone,
        telephone2: formData.telephone2,
        email: formData.email,
      });
      navigate("/Demmande")
      // Handle success (e.g., show a success message or redirect)
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error (e.g., show an error message)
    }
  };
  const reset = ()=>{
    setFormData({
      nomEtPrenom: '',
      typeBuilding: '',
      telephone: '',
      telephone2: '',
      email: '',
    })
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="fit-content"
      sx={{ width: { xl: '50%', md: '50%', sm: '50%', xs: '100%' } }}
    >
          <Typography sx={{color:"#3B4876",fontWeight:700}} variant="h4" align="center" gutterBottom>
            التسجيل في الودادية
          </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ backgroundColor: '#F1F3F4', borderRadius: 1, boxShadow: 1, p: 3 }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <TextField
            fullWidth
            label="Nom Et Prenom"
            name="nomEtPrenom"
            value={formData.nomEtPrenom}
            onChange={handleChange}
            variant="outlined"
            sx={{ mr: 1 }}
          />
          <FormControl variant="outlined" fullWidth sx={{ ml: 1 }}>
            <InputLabel>Type</InputLabel>
            <Select
              name="typeBuilding"
              value={formData.typeBuilding}
              onChange={handleChange}
              label="Type"
            >
              <MenuItem value="">
                <em>Aucune</em>
              </MenuItem>
              {types.map((type)=>(
                <MenuItem value={type.ID_TypeBuilding}>{type.type}</MenuItem>
              ))
                
              }
              
            </Select>
          </FormControl>
        </Box>
        <TextField
          fullWidth
          label="Telephone"
          name="telephone"
          value={formData.telephone}
          onChange={handleChange}
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Téléphone 2"
          name="telephone2"
          value={formData.telephone2}
          onChange={handleChange}
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <Box display="flex" sx={{justifyContent:"center" , flexWrap:"wrap" , rowGap:"10px" , columnGap:"10px"}}>
          <Button type="submit" variant="contained" sx={{ background: '#3B4876', "&:hover": { background: "#fff", color: "#3B4876" } }}>
            Envoyer
          </Button>
          <Button variant="contained" onClick={reset} sx={{ background: '#3B4876', "&:hover": { background: "#fff", color: "#3B4876" } }}>
            Annuler
          </Button>
          <Button variant="contained" onClick={()=>navigate("/Demmande")} sx={{ background: '#3B4876', "&:hover": { background: "#fff", color: "#3B4876" } }}>
            Voir La Liste D'attente
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FormComponent;
