import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import photo from '../img/LISTE2018-1ER-LISTE.jpg'
import photo1 from '../img/MISE-ESTIMCOP-RECAP-LOTIS.jpg'
import photo2 from '../img/Rapport-assemblee-generale-23-04-2024.jpg'
// import required modules
import '../style/style.css'
import Nav from './Nav';
import { useEffect, useState } from 'react';
import axios from 'axios';
export default function SwiperC() {
  const [Annonces, setAnnonces] = useState([]);
  const getAnn = () => {
    axios.get("https://serv.taghzawt.yuliagroupe.com/api/annonces")
        .then((res) => {
            setAnnonces(res.data);
        });
}
useEffect(()=>{
  getAnn()
},[])

  return (
    <>
    <Nav/>
      <Swiper  modules={[Autoplay, Pagination, Navigation]} onClick={()=>""} autoplay={{delay: 2500,disableOnInteraction: false,}} pagination={{clickable: true,}}
        navigation={true} className="mySwiper">
          {Annonces.map((ann)=>(
            <SwiperSlide onClick={()=>window.open("https://serv.taghzawt.yuliagroupe.com/api/annonces/"+ann.ID_Annonce+"/file", "_blank")}><img src={"https://serv.taghzawt.yuliagroupe.com/api/annonces/image/"+ann.ID_Annonce} style={{"object-fit":"contain"}}/></SwiperSlide>
          ))}
        
      </Swiper>
    </>
  );
}
