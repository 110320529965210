import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import "@fontsource/inter/600.css";
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import logo from '../img/logo.png';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    Typography,
    Container,
    TablePagination,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
  } from '@mui/material';
import { Delete, Edit, Add } from '@mui/icons-material';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { useEffect } from 'react';

const drawerWidth = 280;
const theme = createTheme({
  palette: {
      primary: {
          main: '#3B4876',
      },
  },
});

export default function TypeAnnonce() {
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const getProjet=()=>{
    axios.get("https://serv.taghzawt.yuliagroupe.com/api/projects")
    .then((res)=>{
      setProjects(res.data);
    })
  }
  const getTypeAnn=()=>{
    axios.get("https://serv.taghzawt.yuliagroupe.com/api/typeannonces")
    .then((res)=>{
      setTypeAnnonces(res.data);
    })
  }
  useEffect(()=>{
    getProjet()
    getTypeAnn()
  },[])
  const [open, setOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(true);
  const [TypeAnnonces,setTypeAnnonces]=useState([])
  const [IDTypeAnnonce,setIDTypeAnnonce]=useState("")
  const [TypeAnnonce,setTypeAnnonce]=useState("")
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const navigate = useNavigate()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const currentData = TypeAnnonces.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleOpenAdd = () => {
    setIsAdding(true);
    setOpen(true);
  };

  const handleOpenEdit = (TypeAnnonce) => {
    console.log(TypeAnnonce)
    setIsAdding(false);
    setTypeAnnonce(TypeAnnonce.type)
    setIDTypeAnnonce(TypeAnnonce.ID_Typeannonce)
    setProject(TypeAnnonce.project.ID_Project)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (isAdding) {
      const data={
        "type":TypeAnnonce,
        "ID_Project":project
      }
      axios.post("https://serv.taghzawt.yuliagroupe.com/api/typeannonces",data)
      .then((res)=>{
        getTypeAnn()
        console.log(res.data)
      })
    } else {
      
      const data={
        "type":TypeAnnonce,
        "ID_Project":project
      }
      console.log(data)
      axios.put("https://serv.taghzawt.yuliagroupe.com/api/typeannonces/"+IDTypeAnnonce,data)
      .then((res)=>{
        console.log(res)
        getTypeAnn()
      })
    }
    handleClose();
  };

  const HandleDelete=(T)=>{
    
    axios.delete("https://serv.taghzawt.yuliagroupe.com/api/typeannonces/"+T.ID_Typeannonce)
    .then((res)=>{
      getTypeAnn()
      
    })
  }
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '600px' }}>
      <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            background: '#3B4876',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    <Box>
                        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={logo} alt="logo" width="160" />
                        </Toolbar>
                        <Box sx={{ overflow: 'auto', marginTop: '12px' }}>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => navigate("/Admin/Projet")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Projets
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/Annonce")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Annonces
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/TypeAnnonce")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Type Annonces
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/TypeBuilding")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Type Logement
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/ResponceAdmin")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Q&A
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/ListAttende")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            liste d'attente
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                            sx={{
                                width: '100%',
                                borderRadius: 0,
                                mt: 3,
                                mb: 2,
                                backgroundColor: '#fff',
                                '&:hover': { backgroundColor: 'white', color: 'black' },
                            }}
                        >
                            Deconnecter
                        </Button>
                    </Box>
                </Drawer>
        <Container sx={{ marginTop: 5 }}>
          <Typography variant="h4" align="center" gutterBottom>
            Gestion de Type Annonces
          </Typography>

          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            style={{ marginBottom: '20px' }}
            onClick={handleOpenAdd}
          >
            Ajouter Type Annonce
          </Button>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Type Annonces</TableCell>
                  <TableCell>Projet</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentData.map((TypeAnnonce) => (
                  <TableRow key={TypeAnnonce.ID_Typeannonce}>
                    <TableCell>{TypeAnnonce.ID_Typeannonce}</TableCell>
                    <TableCell>{TypeAnnonce.type}</TableCell>
                    <TableCell>{TypeAnnonce.project.Project}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpenEdit(TypeAnnonce)}>
                        <Edit color="primary" />
                      </IconButton>
                      <IconButton onClick={()=>HandleDelete(TypeAnnonce)}>
                        <Delete color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={TypeAnnonces.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 20, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Lignes par page"
            />
          </TableContainer>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{isAdding ? 'Ajouter Type Annonce' : 'Modifier Type Annonce'}</DialogTitle>
            <DialogContent sx={{marginTop:2 , display:"flex",flexDirection:"column" , rowGap:"20px"}}>
              <TextField
              sx={{marginTop:1}}
                label="Nom de Type Annonce"
                value={TypeAnnonce}
                onChange={(e) => setTypeAnnonce(e.target.value)}
                fullWidth
              />
                  <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Projet</InputLabel>
                          <Select label="Type Annonce" fullWidth value={project} onChange={(e)=>setProject(e.target.value)} >
                            {projects.map((project)=>(
                              <MenuItem value={project.ID_Project}>{project.Project}</MenuItem>
                            ))}
                            
                          </Select>
                  </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                  Annuler
              </Button>
              <Button onClick={handleSubmit} color="primary">
                {isAdding ? 'Ajouter' : 'Sauvegarder'}
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
