import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Nav from './Nav';


const StyledTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://serv.taghzawt.yuliagroupe.com/api/demmandes');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
    <Nav/>
    <TableContainer component={Paper} sx={{marginTop:"100px"}}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><b>Nom et Prenom</b></TableCell>
            <TableCell align="left"><b>Type</b></TableCell>
            <TableCell align="left"><b>Date</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:nth-of-type(even)': { backgroundColor: '#f5f5f5' }, '&:hover': { backgroundColor: '#97aeff70' } }}
            >
              <TableCell component="th" scope="row">
                {`${row.nom} ${row.prenom}`}
              </TableCell>
              <TableCell align="left">{row.type}</TableCell>
              <TableCell align="left">{row.created_at.split('T')[0]}</TableCell> {/* Adjust based on date format */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
};

export default StyledTable;
