import React, { useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import PinDropIcon from '@mui/icons-material/PinDrop';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const Footer = () => {
    const [Selected, setSelected] = useState(1);
    const [iframeURL, setIframeURL] = useState('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.8292118718323!2d-6.920915188600181!3d33.89405212595472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda712751dc3fc81%3A0x6f7416a7f44cfbb8!2sTaghzawt%201!5e0!3m2!1sen!2sma!4v1714680452240!5m2!1sen!2sma');

    const ChangeUrlMap = (id, url) => {
        setSelected(id);
        setIframeURL(url);
    };
    let currentYear = new Date().getFullYear();
    return (
        <Box sx={{ height: { lg: "450px", sx: 'fit-content' }, background: "#3B4876", zIndex: 1250, width: "100vw", display: { lg: 'flex', md: 'flex', sm: 'flex', xs: 'block' }, justifyContent: "start", alignItems: "center" }}>
            <Box sx={{ padding: { lg: '0px', xs: '20px 0px' }, display: "flex", justifyContent: "center", width: { xl: "50%", md: "50%", sm: "50%", xs: "100%" }, alignItems: "center", height: "100%", flexDirection: "column" }}>
                <Box sx={{ border: "2px solid #fff",borderTopRightRadius:"5px",borderTopLeftRadius:"5px", borderBottom: "0px", width: '89.3%', height: '50px', display: "flex", justifyContent: 'space-evenly' }}>
                    <Box sx={{ borderRight: "2px solid #fff", width: "-webkit-fill-available", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button sx={{ color: Selected === 1 ? '#3B4876' : "#fff", background: Selected === 1 ? '#fff' : "#3B4876", fontWeight: '650', borderRadius:"0px",fontSize: '14px', width: "100%", height: "100%", ":hover": { color: Selected === 1 ? '#3B4876' : "#fff", background: Selected === 1 ? '#fff' : "#3B4876",borderRadius:"0px" } }} onClick={() => { ChangeUrlMap(1, 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.8292118718323!2d-6.920915188600181!3d33.89405212595472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda712751dc3fc81%3A0x6f7416a7f44cfbb8!2sTaghzawt%201!5e0!3m2!1sen!2sma!4v1714680452240!5m2!1sen!2sma') }}>Taghzawt 1</Button>
                    </Box>
                    <Box sx={{ borderRight: "2px solid #fff", width: "-webkit-fill-available", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button sx={{ color: Selected === 2 ? '#3B4876' : "#fff", background: Selected === 2 ? '#fff' : "#3B4876", fontWeight: '650',  borderRadius:"0px",fontSize: '14px', width: "100%", height: "100%", ":hover": { color: Selected === 2 ? '#3B4876' : "#fff", background: Selected === 2 ? '#fff' : "#3B4876" } }} onClick={() => { ChangeUrlMap(2, 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.608803733568!2d-6.983811088599954!3d33.899727525656196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda70d4ebe3c43b3%3A0x3ef749126e9b5449!2sResidence%20Taghzawt%202!5e0!3m2!1sen!2sma!4v1714680598934!5m2!1sen!2sma') }}>Taghzawt 2</Button>
                    </Box>
                    <Box sx={{ width: "-webkit-fill-available", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button sx={{ color: Selected === 3 ? '#3B4876' : "#fff", background: Selected === 3 ? '#fff' : "#3B4876", fontWeight: '650',  borderRadius:"0px",fontSize: '14px', width: "100%", height: "100%", ":hover": { color: Selected === 3 ? '#3B4876' : "#fff", background: Selected === 3 ? '#fff' : "#3B4876" } }} onClick={() => { ChangeUrlMap(3, 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.519552719867!2d-6.991572250155338!3d33.90202545680716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda70dd0fe0a8d91%3A0x9a0e7f185c6e06b3!2staghzawt%203!5e0!3m2!1sen!2sma!4v1714680677105!5m2!1sen!2sma') }}>Taghzawt 3</Button>
                    </Box>
                </Box>
                <iframe
                    src={iframeURL}
                    width="90%"
                    height="320px"
                    style={{ border: 0, margin: 0 }}
                    allowFullScreen
                    loading="lazy"
                ></iframe>
            </Box>
            <Divider orientation="vertical" sx={{ borderBottomWidth: "medium", background: "#FFFF", height: "70%" }} />
            <Box sx={{ padding: { lg: '0px', xs: '20px 0px' }, width: { lg: '50%', md: '50%', sm: '50%', xs: '100%' }, height: '300px', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{display:"flex",flexDirection:"column",rowGap:"15px",height:"100%",paddingLeft:"20px"}}>
                    <Typography sx={{ color: '#fff', fontWeight: '650', fontSize: '42px', width: "100%" }}>Contact</Typography>
                    <Box >
                        <Box sx={{ display: 'flex' ,columnGap:"10px"}}>
                            <PinDropIcon sx={{ color: 'white' }} />
                            <Typography sx={{ color: '#fff', fontWeight: '650', fontSize: '18px', width: "100%" }}>Avenue Tarik Ibn Zyad Khaizourane 4 Commerce 14-15 Témara</Typography>
                        </Box>
                        <Box sx={{ display: 'flex' ,columnGap:"10px"}}>
                            <MailIcon sx={{ color: 'white' }} />
                            <Typography sx={{ color: '#fff', fontWeight: '650', fontSize: '18px', width: "100%" }}>ass.taghzawt@gmail.com</Typography>
                        </Box>
                                                <Box sx={{ display: 'flex' ,columnGap:"10px"}}>
                            <MailIcon sx={{ color: 'white' }} />
                            <Typography sx={{ color: '#fff', fontWeight: '650', fontSize: '18px', width: "100%" }}>contact@taghzawt.com (bientôt)</Typography>
                        </Box>
                        <Box sx={{ display: 'flex'  ,columnGap:"10px"}}>
                            <LocalPhoneIcon sx={{ color: 'white' }} />
                            <Typography sx={{ color: '#fff', fontWeight: '650', fontSize: '18px', width: "100%" }}>05 37 74 91 90</Typography>
                        </Box>
                        <Box sx={{display:"flex",flexDirection:"column",alignItems:"center",mt:{ xl:10,md:10,sm:10,xs:2}}}>
                        <Typography component="a" href='https://yuliagroup.com' sx={{color:"#fff",fontWeight:700}}>Réalisé par YuliaGroup</Typography>
                            <Typography sx={{color:"#fff",fontWeight:700}}>Copyright © {currentYear}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Footer;
