import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, List, ListItem, Drawer, Toolbar, ListItemText, Divider, ListItemButton, Box, Grid, Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from 'axios';
import logo from '../img/logo.png';
import DeleteIcon from '@mui/icons-material/Delete';
const theme = createTheme({
    palette: {
        primary: {
            main: '#3B4876',
        },
    },
});

// Drawer width for layout
const drawerWidth = 280;

function ResponceAdmin() {
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [responses, setResponses] = useState({});
    const [loading, setLoading] = useState(true);
const getquestion = ()=>{
    axios.get('https://serv.taghzawt.yuliagroupe.com/api/tickets')
    .then(response => {
        setQuestions(response.data);
        setLoading(false);
    })
    .catch(error => {
        console.error("There was an error fetching the questions!", error);
    });
}
    useEffect(() => {
        getquestion()
    }, []);

    const handleResponseChange = (id, response) => {
        setResponses(prevResponses => ({ ...prevResponses, [id]: response }));
    };

    const handleResponseSubmit = (id) => {
        const responseText = responses[id];
        axios.post('https://serv.taghzawt.yuliagroupe.com/api/reponses', {
            ID_Tickets: id,
            response: responseText
        })
        .then(response => {
            console.log(`Response for question ${id} submitted successfully.`);
            getquestion()
        })
        .catch(error => {
            console.error("There was an error submitting the response!", error);
        });
    };
    function formatDate(isoDateString) {
        // Define month names in French
        const monthNames = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
    
        // Create a Date object from the ISO 8601 date string
        const date = new Date(isoDateString);
    
        // Extract the day, month, and year
        const day = date.getDate();
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
    
        // Format the date as "15 mai 2024"
        return `${day} ${month} ${year}`;
    }

    const HandleDelete=(Q)=>{
    
        axios.delete("https://serv.taghzawt.yuliagroupe.com/api/tickets/"+Q.ID_Tickets)
        .then((res)=>{
          getquestion()
        })
    
      }
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '600px' , columnGap:3 }}>
                {/* Permanent drawer for navigation */}
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            background: '#3B4876',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    <Box>
                        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={logo} alt="logo" width="160" />
                        </Toolbar>
                        <Box sx={{ overflow: 'auto', marginTop: '12px' }}>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => navigate("/Admin/Projet")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Projets
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/Annonce")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Annonces
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/TypeAnnonce")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Type Annonces
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/TypeBuilding")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Type Logement
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/ResponceAdmin")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Q&A
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/ListAttende")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            liste d'attente
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                            sx={{
                                width: '100%',
                                borderRadius: 0,
                                mt: 3,
                                mb: 2,
                                backgroundColor: '#fff',
                                '&:hover': { backgroundColor: 'white', color: 'black' },
                            }}
                        >
                            Deconnecter
                        </Button>
                    </Box>
                </Drawer>
                {/* Main content */}

                <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="fit-content"
      sx={{ width: { xl: "70%", md: "70%", sm: "70%", xs: "90%" }, mt: 6 }}
    >
                  <Typography variant="h4" align="center" gutterBottom>
            Questions & Réponses
          </Typography>
      <Grid container spacing={2}>
        {questions.map((row, index) => (
          <Grid item xs={12} key={index}>
            <Card sx={{ boxShadow: 3 , background:'#F1F3F4'}}>
              <CardContent>
              <Typography variant="h6" component="div" gutterBottom color="green" sx={{fontWeight:700,display:"flex" , justifyContent:"space-between"}}>
                  {formatDate(row.created_at)}
                  <Button color="error" onClick={()=>HandleDelete(row)}><DeleteIcon/></Button>
                </Typography>
                <Divider/>
              <Typography variant="h6" component="div" gutterBottom sx={{display:"flex", mt:1}}>
              <Typography sx={{color:"red" , pr:1 , fontWeight:700}}>Nom et Prénom : </Typography> {row.nom}
                </Typography>
                <Divider/>
                <Typography variant="h6" component="div" gutterBottom sx={{display:"flex", mt:1}}>
                <Typography sx={{color:"red" , pr:1 , fontWeight:700}}>Question : </Typography>{row.msg}
                </Typography>
                <Divider/>
                <Typography variant="h6" component="div" gutterBottom sx={{display:"flex", mt:1}}>
                <Typography sx={{color:"red" , pr:1 , fontWeight:700}}>Projet : </Typography>{row.project ? row.project.Project: "Pas de Projet Selecter"}
                </Typography>
                <Divider/>
                <Typography variant="body1" sx={{display:"flex" , mt:1}}>
                   <Typography sx={{color:"red" , pr:1 , fontWeight:700}}>Bureau : </Typography> {row.responses.length !== 0? <Typography>{row.responses[0].response}</Typography> :
                                        <>
                                        <TextField
                                            sx={{width:"70%"}}
                                            label="Your Response"
                                            variant="outlined"
                                            value={responses[row.ID_Tickets] || ''}
                                            onChange={(e) => handleResponseChange(row.ID_Tickets, e.target.value)}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleResponseSubmit(row.ID_Tickets)}
                                            style={{ marginLeft: 16 }}
                                        >
                                            Submit
                                        </Button>
                                        </>}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      </Box>
            </Box>
        </ThemeProvider>
    );
}

export default ResponceAdmin;
