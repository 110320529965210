import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import "@fontsource/inter/600.css";
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import PinDropIcon from '@mui/icons-material/PinDrop';
import MailIcon from '@mui/icons-material/Mail';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { createTheme } from '@mui/material/styles';
import { Card, CardContent } from '@mui/material';

import pdf from "../img/fichier-pdf.png"
import Nav from '../components/Nav';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Footer from '../components/Footer';

const drawerWidth = 280;
const theme = createTheme({
  palette: {
      primary: {
          main: '#3B4876', // Customize this color
      },

  },
});
export default function Annonces() {
  const { project } = useParams();
  const [TypeAnnonces,setTypeAnnonces]=useState([])
  const [selectTypeAnnonce,setselectTypeAnnonce]=useState()
  const [Annonces , setAnnonces] = useState([])
  const [selectnomTypeAnnonce,setselectnomTypeAnnonce] = useState()
  const getTypeAnn=()=>{
    axios.get("https://serv.taghzawt.yuliagroupe.com/api/projects/"+project+"/typeannonces")
    .then((res)=>{
      setTypeAnnonces(res.data);
      setselectTypeAnnonce(res.data[0] ? res.data[0].ID_Typeannonce : null)
      setselectnomTypeAnnonce(res.data[0] ? res.data[0].type : null)
    })
}
const getAnn=()=>{
  axios.get("https://serv.taghzawt.yuliagroupe.com/api/typeannonces/"+selectTypeAnnonce+"/annonces")
  .then((res)=>{
    setAnnonces(res.data);
  })
}

      React.useEffect(()=>{
        getAnn()
      },[selectTypeAnnonce])
      React.useEffect(()=>{
        getTypeAnn()
      },[project])

  const [iframeURL , setiframURL] = useState('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.8292118718323!2d-6.920915188600181!3d33.89405212595472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda712751dc3fc81%3A0x6f7416a7f44cfbb8!2sTaghzawt%201!5e0!3m2!1sen!2sma!4v1714680452240!5m2!1sen!2sma')
  const [ Selected , setSeleted ] = useState(1)
  const ChangeUrlMap= (Selected , Url) =>{
    setiframURL(Url)
    setSeleted(Selected)
  }

  return (
    <ThemeProvider theme={theme}>
    <Box sx={{ display: 'flex' , minHeight:"600px" }}>
      <Nav setselectTypeAnnonce={setselectTypeAnnonce}/>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box',background:"#F1F3F4" },
          display:{xs:"none",sm:"block"},
          '& .css-12i7wg6-MuiPaper-root-MuiDrawer-paper,.css-1l8j5k8':{position:"unset"}
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto', marginTop:"5px" }}>

          
          {TypeAnnonces.map((type)=>(
            <>
          <List disablePadding>
          <ListItem  disablePadding onClick={()=>{setselectTypeAnnonce(type.ID_Typeannonce) ; setselectnomTypeAnnonce(type.type)}}>
            <ListItemButton sx={{textAlign:"center"}}>
            <Typography sx={{textAlign:"center",fontFamily:"Inter" , fontWeight:"700", color:"#3B4876",width:"100%"}}>{type.type}</Typography>
            </ListItemButton>
          </ListItem>
      </List>
      <Divider sx={{borderBottomWidth:"medium",background:"#3B4876"}}/>
      </>
          ))}

          
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 ,marginTop:10 ,  display:"flex", flexDirection:"column" ,rowGap:2 , alignItems:"center" }}>
        <Typography sx={{fontSize:"18px" , fontWeight:700 , color:"#3B4876"}}>Annonces de : {selectnomTypeAnnonce}</Typography>
        {Annonces.map((ann)=>(
    <Card sx={{ minWidth: 200, background: "#F1F3F4", boxShadow: "none",cursor: 'pointer', width: { lg: "82%", md: "80%", sm: "90%", xs: "100%" } }} onClick={()=>window.open("https://serv.taghzawt.yuliagroupe.com/api/annonces/"+ann.ID_Annonce+"/file")}>
    <CardContent sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Typography sx={{ fontSize: { lg: 20, md: 18, sm: 15, xs: 13 }, color: "#3B4876", fontFamily: "inherit", fontWeight: "700", textAlign: { xs: "center" }, width: "90%" }} color="text.secondary" gutterBottom>
        {ann.Label}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "end", width: "10%" }}>
        <img src={pdf} height={40} alt="pdf" />
      </Box>
    </CardContent>
  </Card>
        ))}


      </Box>
    </Box>
      <Footer/>
    </ThemeProvider>
  );
}