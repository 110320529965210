import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import "@fontsource/inter/600.css";
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import logo from '../img/logo.png';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    Typography,
    Container,
    TablePagination,
  } from '@mui/material';
import { Delete, Edit, Add } from '@mui/icons-material';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const drawerWidth = 280;
const theme = createTheme({
  palette: {
      primary: {
          main: '#3B4876',
      },
  },
});

export default function TypeBuilding() {
  const [Annonces, setAnnonces] = useState([]);

  const [open, setOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(true);
  const [currentAnnonce, setCurrentAnnonce] = useState({ ID_TypeBuilding: null, type: '' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const navigate = useNavigate()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const currentData = Annonces.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const getType=()=>{
    axios.get("https://serv.taghzawt.yuliagroupe.com/api/typebuildings")
    .then((res)=>{
      setAnnonces(res.data)
    })
  }
React.useEffect(()=>{
  getType()
},[])

  const handleOpenAdd = () => {
    setIsAdding(true);
    setCurrentAnnonce({ ID_TypeBuilding: null, type: '' });
    setOpen(true);
  };

  const handleOpenEdit = (Annonce) => {
    setIsAdding(false);
    setCurrentAnnonce(Annonce);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (isAdding) {
      const newAnnonce = {  type: currentAnnonce.type };
      axios.post('https://serv.taghzawt.yuliagroupe.com/api/typebuildings',newAnnonce)
      .then((res)=>{
        getType()
      })
    } else {
      axios.put('https://serv.taghzawt.yuliagroupe.com/api/typebuildings/'+currentAnnonce.ID_TypeBuilding,{type:currentAnnonce.type})
      .then((res)=>{
        getType()
      })
    }
    handleClose();
  };

  const HandleDelete=(T)=>{
    
    axios.delete("https://serv.taghzawt.yuliagroupe.com/api/typebuildings/"+T.ID_TypeBuilding)
    .then((res)=>{
      getType()
      
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '600px' }}>
      <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            background: '#3B4876',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    <Box>
                        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={logo} alt="logo" width="160" />
                        </Toolbar>
                        <Box sx={{ overflow: 'auto', marginTop: '12px' }}>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => navigate("/Admin/Projet")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Projets
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/Annonce")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Annonces
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/TypeAnnonce")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Type Annonces
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/TypeBuilding")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Type Logement
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/ResponceAdmin")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            Q&A
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/Admin/ListAttende")}>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                color: '#fff',
                                                width: '100%',
                                            }}
                                        >
                                            liste d'attente
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ borderBottomWidth: 'medium', background: '#fff' }} />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                            sx={{
                                width: '100%',
                                borderRadius: 0,
                                mt: 3,
                                mb: 2,
                                backgroundColor: '#fff',
                                '&:hover': { backgroundColor: 'white', color: 'black' },
                            }}
                        >
                            Deconnecter
                        </Button>
                    </Box>
                </Drawer>
        <Container sx={{ marginTop: 5 }}>
          <Typography variant="h4" align="center" gutterBottom>
            Gestion de Type de logement
          </Typography>

          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            style={{ marginBottom: '20px' }}
            onClick={handleOpenAdd}
          >
            Ajouter Type Logement
          </Button>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>type de Logement</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentData.map((Annonce) => (
                  <TableRow key={Annonce.ID_TypeBuilding}>
                    <TableCell>{Annonce.ID_TypeBuilding}</TableCell>
                    <TableCell>{Annonce.type}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpenEdit(Annonce)}>
                        <Edit color="primary" />
                      </IconButton>
                      <IconButton>
                        <Delete color="error" onClick={()=>HandleDelete(Annonce)} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={Annonces.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 20, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Lignes par page"
            />
          </TableContainer>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{isAdding ? 'Ajouter type de Logement' : 'Modifier type de Logement'}</DialogTitle>
            <DialogContent>
              <TextField
                label="Nom type de Logement"
                value={currentAnnonce.type }
                onChange={(e) => setCurrentAnnonce({ ...currentAnnonce, type: e.target.value })}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Annuler
              </Button>
              <Button onClick={handleSubmit} color="primary">
                {isAdding ? 'Ajouter' : 'Sauvegarder'}
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
